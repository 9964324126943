<template>
    <main>
        <!-- Modal -->
        <modalOrdenesComponent :ordenes="ordenes" :usuario="usuario"></modalOrdenesComponent>
        <div class="card card-header-actions shadow-none border-0">
            <div class="card-header">
                <h4 class="card-title mb-0">Nueva orden de Medicamento</h4>
                <button @click="historialOrdenes();" type="button" class="btn btn-outline-primary btn-sm"><i
                        class="fas fa-eye fa-fw"></i>Ver Ordenes</button>
            </div>
            <div class="card-body ">
                <div class="form-group mt-2">
                    <label class="form-label">Medicamento <small class="text-danger">*</small></label>
                    <v-select-servicio :groups="[9, 12, 13]" v-model="item.servicio" mode="object"
                        :class="{ 'is-error': $v.item.servicio.$error }">
                    </v-select-servicio>
                    <span v-if="!$v.item.servicio.required && $v.item.servicio.$dirty" class="text-danger">
                        <i class="fas fa-exclamation-triangle"></i> Este campo es requerido.
                    </span>
                </div>
                <div class="form-row">
                    <div class="form-group col-md-2">
                        <label class="form-label">Cantidad <small class="text-danger">*</small></label>
                        <input type="number" v-model="item.cantidad" class="form-control form-control-sm"
                            :class="{ 'is-invalid': $v.item.cantidad.$error && $v.item.cantidad.$dirty }">
                    </div>
                    <div class="form-group col-md-8">
                        <label class="form-label">Indicaciones <small class="text-danger">*</small></label>
                        <input type="text" class="form-control form-control-sm" v-model="item.observacion"
                            :class="{ 'is-invalid': $v.item.observacion.$error && $v.item.observacion.$dirty }">
                    </div>
                    <div class=" mt-4 py-2 col-md-2  text-center">
                        <button class="btn btn-primary btn-sm" @click="agregarServicio()">
                            <i class="fas fa-plus fa-fw"></i>Agregar
                        </button>
                    </div>
                </div>

                <div class="table-responsive">
                    <table class="table table-borderless mb-0 table-sm">
                        <thead class="border-bottom">
                            <tr class="small text-uppercase text-muted">
                                <th scope="col">Medicamento</th>
                                <th scope="col">Cantidad</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr class="border-bottom" v-for="(item, index) in items" :key="item.id">
                                <td>
                                    <div class="font-weight-bold">
                                        {{ item.servicio.codigo }}
                                        {{ item.servicio.descripcion }}
                                        <div class="small text-muted d-none d-md-block">
                                            Observación: {{ item.observacion }}
                                        </div>
                                    </div>
                                </td>
                                <td>{{ item.cantidad }}</td>
                                <td>
                                    <button class="btn btn-transparent rounded-circle" @click="removerServicio(index)">
                                        <i class="fas fa-trash-alt"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="form-group mt-4">
                    <label class="form-label">Observaciones </label>
                    <div class="">
                        <textarea v-model="observaciones" class="form-control"></textarea>
                    </div>
                </div>
                <button class="btn btn-success " @click="guardar()">
                    <i class="fas fa-save"></i>&nbsp;Guardar
                </button>
            </div>
        </div>
    </main>
</template>
<script>
import vSelectServicio from "../../../../components/common/VSelectServicio";
import ordenClinicaService from '../../../../services/ordenClinicaService';
import modalOrdenesComponent from './modalOrdenesComponent';
import $ from 'jquery';
import { required } from "vuelidate/lib/validators";
import { isEmpty } from 'lodash';
import Toast from '../../../../components/common/utilities/toast';

export default {
    props: ['idHistoria', 'usuario'],
    components: { vSelectServicio, modalOrdenesComponent },
    data() {
        return {
            id_historia: '',
            observaciones: '',
            item: {
                cantidad: '',
                observacion: '',
                servicio: {},
            },
            items: [],
            ordenes: [],
        }
    },
    validations() {
        return {
            item: {
                cantidad: { required },
                observacion: { required },
                servicio: { required },
            }
        }
    },
    methods: {
        agregarServicio() {
            this.$v.item.$touch();
            if (this.$v.item.$invalid) {
                return;
            }
            this.items.push({ ...this.item });
        },
        removerServicio(index) {
            this.items.splice(index, 1);
        },
        async guardar() {
            try {

                if (isEmpty(this.items)) {
                    Toast.fire({
                        icon: 'error',
                        title: 'Debe agregar por lo menos un servicio'
                    });
                    return;
                }

                const orden = {
                    id_historia: this.idHistoria,
                    observaciones: this.observaciones,
                    item_ordenes: this.items,
                    type_orden: 'medicamento'
                };
                this.LoaderSpinnerShow();
                await ordenClinicaService.store(orden);
                this.limpiarCampos();
                this.LoaderSpinnerHide();

                Toast.fire({
                    icon: 'success',
                    title: 'Datos guardados con exito'
                });
            } catch (error) {
                console.error(error);
                this.LoaderSpinnerHide();
                Toast.fire({
                    icon: 'error',
                    title: 'Ocurrio un error al procesar la solicitud'
                });
            }
        },
        async historialOrdenes() {
            $('#modalOrden').modal('show');
            const response = await ordenClinicaService.showIdHistoriaAndType({
                id_historia: this.idHistoria,
                type: 'medicamento'
            });
            this.ordenes = response.data;
            //console.log(this.orden);
        },
        limpiarCampos() {
            this.observaciones = '';
            this.item = {};
            this.items = [];
        },
    }
}
</script>
