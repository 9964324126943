<template>
<main>
    <div class="row">
        <indexRemision :idHistoria="idHistoria" :usuario="usuario"></indexRemision>
        <incapacidad-component :idHistoria="idHistoria" :usuario="usuario"></incapacidad-component>
        <button class="btn btn-success btn-sm mx-2" @click="cerrar">
            <i class="fas fa-flag-checkered fa-fw"></i>
            Cerrar Historia
        </button>
    </div>
</main>
</template>

<script>

import Swal from "sweetalert2";
import historiaClinicaService from "@/services/historiaClinicaService";
import indexRemision from '../baseRemision/index';
import incapacidadComponent from "../baseIncapacidades/index";
export default {
  props : ['idHistoria', 'idUsuario','usuario'],
  components: {indexRemision,incapacidadComponent},
  name: "cerrarHistoria",
  methods : {
    async cerrar(){

      const result = await Swal.fire({
        title :  "¿Está seguro de cerrar la historia ?",
        text : "Al cerrar la historia no se podran realizar cambios",
        showCancelButton : true,
        showConfirmButton : true,
        confirmButtonText : 'Sí, cerrar',
        cancelButtonText : 'No',
        icon : "warning",
        showLoaderOnConfirm : true,
        preConfirm: async () => {
          try{
            await historiaClinicaService.cerrarHistoria({
              id_historia : this.idHistoria
            });
          }catch (e) {
            console.error(e);
            Swal.showValidationMessage('ha ocurrido un error al procesar la solicitud');
          }

        },
      });

      if(result.isConfirmed){
        await Swal.fire('Exíto', 'Historia Cerrada con exíto', 'success');
        this.$router.push({
          name : 'historias.perfil.usuario',
          params : {
            idUsuario : this.idUsuario
          }
        });
      }
    }
  }
}
</script>

<style scoped>

</style>